export enum ActionType {
  ADD_PARENT_PENDING = 'addParentPending',
  ADD_PARENT_SUCCESS = 'addParentSuccess',
  ADD_PARENT_FAIL = 'addParentFail',
  UPDATE_COUPON_CODE = 'updateCouponCode',
  UPDATE_CHANGE_STATUS = 'updateChangeStatus',
  UPDATE_PARENT_EMAIL = 'updateParentEmail',
  RESET_ERROR_MESSAGE = 'resetParentErrorMessage',
  RESET_LOADING_STATUS = 'resetParentLoadingStatus',
  RESET = 'resetParent'
}

export interface ParentDetailsType {
  customer: {
    name: string
    surname: string
    email: string
    phoneNumber: string
    marketingOptIn: boolean
    marketingCookieConsent?: boolean
  }
  coupon: string
}
export interface errorMessageParentType {
  popup: {
    message: string
    action: string
    url: string | null
    actionText: string | null
  }
}
interface AddParentPendingAction {
  type: ActionType.ADD_PARENT_PENDING
}

interface AddParentSuccessAction {
  type: ActionType.ADD_PARENT_SUCCESS
  payload: { parentDetails: ParentDetailsType; changed: boolean }
}

interface AddParentFailAction {
  type: ActionType.ADD_PARENT_FAIL
  payload: {
    errorMessage: errorMessageParentType
  }
}

interface UpdateCouponCodeAction {
  type: ActionType.UPDATE_COUPON_CODE
  payload: string
}

interface UpdateChangeAction {
  type: ActionType.UPDATE_CHANGE_STATUS
  payload: boolean
}

interface UpdateParentEmailAction {
  type: ActionType.UPDATE_PARENT_EMAIL
  payload: string
}

interface ResetErrorMessageAction {
  type: ActionType.RESET_ERROR_MESSAGE
}

interface ResetLoadingStatusAction {
  type: ActionType.RESET_LOADING_STATUS
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | AddParentPendingAction
  | AddParentSuccessAction
  | AddParentFailAction
  | UpdateChangeAction
  | UpdateCouponCodeAction
  | UpdateParentEmailAction
  | ResetErrorMessageAction
  | ResetLoadingStatusAction
  | ResetAction
