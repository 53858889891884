import { Action, ActionType, ParentDetailsType, errorMessageParentType } from '../types/parentType'

interface StateType {
  parentDetails: ParentDetailsType
  loading: boolean
  error: boolean
  changed: boolean
  errorMessage: errorMessageParentType | null
}

const initialState: StateType = {
  parentDetails: {
    customer: {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      marketingOptIn: ''
    },
    coupon: '',
    couponCodes: ''
  },
  loading: false,
  error: false,
  changed: false,
  errorMessage: null
}

const addParentReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_PARENT_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_PARENT_SUCCESS:
      return {
        ...state,
        parentDetails: action.payload.parentDetails,
        loading: false,
        changed: action.payload.changed
      }
    case ActionType.ADD_PARENT_FAIL:
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage }
    case ActionType.UPDATE_COUPON_CODE:
      return {
        ...state,
        parentDetails: { ...state.parentDetails, coupon: action.payload, couponCodes: action.payload }
      }
    case ActionType.UPDATE_PARENT_EMAIL:
      return {
        ...state,
        parentDetails: { ...state.parentDetails, customer: { ...state.parentDetails.customer, email: action.payload } }
      }
    case ActionType.UPDATE_CHANGE_STATUS:
      return { ...state, changed: action.payload }
    case ActionType.RESET_LOADING_STATUS:
      return { ...state, loading: false }
    case ActionType.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null
      }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default addParentReducer
